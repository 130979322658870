/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Logo from "../images/logo.svg"

const footer = props => {
  return (
    <footer sx={{ background: `rgba(58, 93, 127, 0.09)` }}>
      <div
        className="grid-container"
        style={{ paddingTop: `2rem`, paddingBottom: `2rem` }}
      >
        <div className="grid-x">
          <div
            className="large-3 medium-3 small-12 cell"
            sx={{ m: `2.5rem 0` }}
          >
            <img
              className="nav__logo"
              src={Logo}
              width="100"
              height="105"
              alt="Logo"
            ></img>
          </div>
          <div
            className="large-3 medium-3 small-12 cell"
            sx={{ m: `2.5rem 0` }}
          >
            <h3 className="footer-title">tinyML</h3>
            <p className="footer-text">
              <Link to="/about">About</Link>
              <br />
              <Link to="/files">Downloads</Link>
              <br />
              <Link to="/contact">Contact</Link>
            </p>
          </div>
          <div
            className="large-3 medium-3 small-12 cell"
            sx={{ m: `2.5rem 0` }}
          >
            <h3 className="footer-title">Links</h3>
            <p className="footer-text">
              <Link to="/events">Events</Link>
              <br />
              <a href="https://www.meetup.com/pro/tinyml/" target="__blank">
                Meetups
              </a>
            </p>
          </div>
          <div
            className="large-3 medium-3 small-12 cell"
            sx={{ m: `2.5rem 0` }}
          >
            <h3 className="footer-title">Notices</h3>
            <p className="footer-text">
              <Link to="/terms-of-use">Terms of Use</Link>
              <br />
              <Link to="/privacy">Privacy</Link>
              <br />
              <Link to="/cookie-policy">Cookie Policy</Link>
              <br />
              <Link to="/policies">Policies</Link>
            </p>
          </div>
        </div>
      </div>
      <div className="socket">
        <div className="grid-container">
          <div className="grid-x large-12">
            © {new Date().getFullYear()} tinyML® and the tinyML logo are
            trademarks of tinyML Foundation
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
